import axios from "axios";
import { authHeader } from "../_helper/auth-header";



// const rootUrl = 'http://localhost:4546/api/v1/'
const rootUrl = 'https://service.winonwiz.com:4546/api/v1/'


const authURL = rootUrl + 'auth';
const dashboardURL = rootUrl + 'dashboard';
const homePageURL = rootUrl + 'home';
const pagesURL = rootUrl + 'pages';
const funfactURL = rootUrl + 'funfacts';
const gamesURL = rootUrl + 'game';
const contestURL = rootUrl + 'contest';
const settingURL = rootUrl + 'settings';
const questionURL = rootUrl + 'questions';
const answerURL = rootUrl + 'answers';
const blogURL = rootUrl + 'blogs';

/*********** Auth ***********/
async function login(loginData) {
  return await axios.post(authURL + '/admin/login', loginData);
}
async function getDashboard() {
  return await axios.get(dashboardURL,{headers: await authHeader()});
}

async function gethomePage(){
  return await axios.get( homePageURL+ '/admin',{
    headers: await authHeader(),
  })
}
async function UpdateHomePageData(data){
  return await axios.patch( homePageURL, data, {
    headers: await authHeader(),
  })
}

/*********  Pages *********/
async function getPage() {
  return await axios.get(pagesURL, {
    headers: await authHeader(),
  });
}
async function pagesData(id) {
  return await axios.get(pagesURL + '/' + id, {
    headers: await authHeader(),
  });
}
async function updatePage(id, title, desc) {
  return await axios.patch(pagesURL + '/' + id, { title, desc }, {
    headers: await authHeader(),
  });
}

async function fetchSettings() {
  return await axios.get(settingURL + '/default' , {
    headers: await authHeader(),
  });
}

async function updateNewSetting(data) {
  return await axios.patch(settingURL, data , {
    headers: await authHeader(),
  });
}

async function getFunFacts(limit, offset, status, keyword) {
  return await axios.get(funfactURL+ '/admin?limit='+limit+'&offset='+offset+'&keyword='+keyword+'&status='+status,{
    headers: await authHeader(),
  });
}

async function addfunfact(data) {
  return await axios.post(funfactURL, data ,{
    headers: await authHeader(),
  });
}

async function updatefunfact(id, facts) {
  return await axios.patch(funfactURL + '/' + id, {facts: facts} ,{
    headers: await authHeader(),
  });
}
async function updatefunFactStatusData(id, status) {
  return await axios.put(funfactURL + '/' + id, {status: status} ,{
    headers: await authHeader(),
  });
}

async function getgames(limit, offset, status, keyword) {
  return await axios.get(gamesURL+ '/admin?limit='+limit+'&offset='+offset+'&keyword='+keyword+'&status='+status,{
    headers: await authHeader(),
  });
}

async function addgame(name) {
  return await axios.post(gamesURL, name ,{
    headers: await authHeader(),
  });
}

async function updategame(id, name) {
  return await axios.patch(gamesURL +'/' +id, {name} ,{
    headers: await authHeader(),
  });
}
async function updategameStatusData(id, status) {
  return await axios.put(gamesURL + '/' + id, {status: status} ,{
    headers: await authHeader(),
  });
}
async function updategameImage(id, file) {
  return await axios.put(gamesURL + '/icon/' + id, {file} ,{
    headers: await authHeader('FormData'),
  });
}

async function getcontests(limit, offset, status, keyword, gameId) {
  return await axios.get(contestURL+ '/admin?limit='+limit+'&offset='+offset+'&keyword='+keyword+'&status='+status+'&gameId='+gameId,{
    headers: await authHeader(),
  });
}

async function addcontest(data) {
  return await axios.post(contestURL, data ,{
    headers: await authHeader(),
  });
}

async function updatecontest(id, data) {
  return await axios.patch(contestURL +'/' +id, data ,{
    headers: await authHeader(),
  });
}
async function updatecontestStatusData(id, status) {
  return await axios.put(contestURL + '/' + id, {status: status} ,{
    headers: await authHeader(),
  });
}


async function fetchQuestions(limit, offset, status, keyword, contestId) {
  return await axios.get(questionURL+ '/admin?limit='+limit+'&offset='+offset+'&status='+status+'&contestId='+contestId+'&keyword='+keyword,{
    headers: await authHeader(),
  });
}

async function addquestion(data) {
  return await axios.post(questionURL, data ,{
    headers: await authHeader(),
  });
}

async function updatequestion(id, name) {
  return await axios.patch(questionURL +'/' +id, {name : name} ,{
    headers: await authHeader(),
  });
}
async function updatequestionStatusData(id, status) {
  return await axios.put(questionURL + '/' + id, {status: status} ,{
    headers: await authHeader(),
  });
}


async function fetchanswers(questionId) {
  return await axios.get(answerURL+ '/'+questionId,{
    headers: await authHeader(),
  });
}

async function addanswer(data) {
  return await axios.post(answerURL, data ,{
    headers: await authHeader(),
  });
}

async function updateanswer(id, ans, correct) {
  return await axios.patch(answerURL +'/' +id, {ans : ans, correct: correct} ,{
    headers: await authHeader(),
  });
}
async function updateanswerStatusData(id) {
  return await axios.delete(answerURL + '/' + id,{
    headers: await authHeader(),
  });
}

async function getBlogs(limit, offset, status,  keyword) {
  return await axios.get(blogURL + '?limit='+limit+'&offset='+offset+'&keyword='+keyword+'&status='+status,{
    headers: await authHeader(),
  });
}
async function createBlogs(data) {
  return await axios.get(blogURL + data,{
    headers: await authHeader(),
  });
}
async function updateBlogsdata(id, title, author, desc, date) {
  return await axios.patch(blogURL + '/' + id, { title: title, author: author, desc: desc, date: date }, {
    headers: await authHeader()
  })
}



export const service = {
  login,
  getDashboard,
  gethomePage, UpdateHomePageData,
  getFunFacts, addfunfact, updatefunfact, updatefunFactStatusData,
  getgames, addgame, updategame, updategameStatusData, updategameImage,
  getcontests, addcontest, updatecontest, updatecontestStatusData,
  fetchQuestions, addquestion, updatequestion, updatequestionStatusData,
  fetchanswers, addanswer, updateanswer, updateanswerStatusData,
  getBlogs, createBlogs, updateBlogsdata,
  getPage, pagesData, updatePage,
  fetchSettings, updateNewSetting,

}
