import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  gameData: [],
  gameCount: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,
}

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setgameData(state, { payload }) {
      state.gameData = payload.result
      state.gameCount = payload.count
    },
    updategamesData(state, { payload }) {
      const objIndex = state.gameData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.gameData[objIndex] = payload
      }
    },
    addGameData(state, { payload }) {
      state.gameData.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    },
  }
})

export const { setgameData, addGameData, updategamesData, isOpenModal, ModalToggle, isOpenStatusModal, statusToggle,
  isImageOpenModal, ImagestatusToggle,
 } = gameSlice.actions;

export default gameSlice.reducer;

export function getgame(limit, offset, status, keyword) {
  return async function getgameThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getgames(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setgameData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addgameData(payload) {
  return async function addgameThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addgame(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          dispatch(addGameData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updategameData(payload) {
  return async function updategameThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updategame(payload.id, payload.name).then(
        (response) => {
          dispatch(updategamesData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdategame(payload) {
  return async function statusUpdategameThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updategameStatusData(payload.id, payload.status).then(
        (response) => {

          dispatch(updategamesData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusDeletegameStatus(id, status) {
  return async function statusDeletegamesThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updategameStatusData(id, status).then(
        (response) => {
          dispatch(updategamesData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateImageGame(id, payload) {
  return async function statusDeletegamesThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updategameImage(id, payload).then(
        (response) => {
          dispatch(ImagestatusToggle());
          dispatch(updategamesData(response.data))
          dispatch(setLoading(false))
          successHandler('Logo Uploaded Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

