import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import { errorHandler } from '../shared/_helper/responseHelper';

const initialState = {
  activeVideos: 0,
  categories: 0,
  deactiveVideos: 0,
  languages: 0,
}

export const loadingSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardData(state, { payload }) {
      state.activeVideos = payload.activeVideos
      state.categories = payload.categories
      state.deactiveVideos = payload.deactiveVideos
      state.languages = payload.languages
    },

  }
})

export const { setDashboardData } = loadingSlice.actions;

export default loadingSlice.reducer;

/*LOGIN GET OTP*/
export function getDashboardData() {
  return async function getDashboardDataThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getDashboard().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setDashboardData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}



