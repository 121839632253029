import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import { getDashboardData } from '../../../store/dashboardSlice';
// import TodoContain from '../../Todo';
// import { useNavigate } from "react-router-dom";

const Social = () => {
  const storeVar = useSelector(state => state.dashboard)
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    dispatch(getDashboardData())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' parent='Dashboard' title='' />
      <Container fluid={true}>
        <Row>
          <Col xxl='12' xl='12' >
            <Row>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Active Stream' gros={70} total={storeVar.activeVideos} color='success' icon='movies' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Deactive Stream' gros={50} total={storeVar.deactiveVideos} color='secondary' icon='movies' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Language' gros={20} total={storeVar.languages} color='warning' icon='languages' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Category' gros={70} total={storeVar.categories} color='primary' icon='category' />
            </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Social;
