// dashbaord
import Social from "../Components/Dashboard/Social";
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
import Pages from '../Pages/Page'
import EditPage from '../Pages/PageEdit'
import HomePage from '../Pages/Homepage';
import Setting from '../Pages/Settings';
import Funfact from '../Pages/FunFact';
import GameCategory from '../Pages/GameCategory';
import Contest from '../Pages/Contest';
import Question from '../Pages/Question';
import Answer from '../Pages/Answer';
import Blog from '../Pages/Blogs'

export const routes = [
  { path: `/dashboard/:layout`, Component: <Social /> },
  { path: `/homepage/:layout`, Component: <HomePage /> },
  { path: `/funfact/:layout`, Component: <Funfact /> },
  { path: `/game/:layout`, Component: <GameCategory /> },
  { path: `/game/contest/:layout`, Component: <Contest /> },
  { path: `/game/contest/question/:layout`, Component: <Question /> },
  { path: `/game/contest/question/answer/:layout`, Component: <Answer /> },

  { path: `/blog/:layout`, Component: <Blog /> },
  { path: `/pages/:layout`, Component: <Pages /> },
  { path: `/edit-page/:layout`, Component: <EditPage /> },
  { path: `/settings/:layout`, Component: <Setting /> },



  //Error
  { path: `*`, Component: <ErrorPage4 /> },

];
