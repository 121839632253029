import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch } from 'react-redux';
import { updateNewSettingData } from '../../store/settingsSlice';

const SettingsTableModel = (props) => {
  const storeVar = props.props;
  const dispatch = useDispatch();

  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    wpNumber: '',
    fbLink: '',
    instaLink: '',
  });

  useEffect(() => {
    if (storeVar) {
      setFormVar({
        wpNumber: storeVar?.wpNumber || '',
        fbLink: storeVar?.fbLink || '',
        instaLink: storeVar?.instaLink || '',

      });
    }
  }, [storeVar]);

  const submitNewSettings = () => {

    if (wpNumberValid() || fbLinkValid() || instaLinkValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    dispatch(updateNewSettingData({wpNumber: formVar.wpNumber, fbLink: formVar.fbLink, instaLink: formVar.instaLink }))
  }
  const wpNumberValid = () => {
    if (!formVar.wpNumber) {
      return "Whatsapp No is required";
    }
  }
  const fbLinkValid = () => {
    if (!formVar.fbLink) {
      return "Facebook link is required";
    }
  }
  const instaLinkValid = () => {
    if (!formVar.instaLink) {
      return "Instagram link is required";
    }
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>

              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">Whatsapp No.</Label>
                  <Input className="form-control" type="text" placeholder='Enter WhatsApp Number' onChange={(e) => { if (/^\d{0,10}$/.test(e.target.value)) { setFormVar((prevFormVar) => ({ ...prevFormVar, wpNumber: e.target.value })) } }} value={formVar?.wpNumber} minLength={10} maxLength={10} />
                  {submit && wpNumberValid() ? <span className='d-block font-danger'>{wpNumberValid()}</span> : ""}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">Facebook Link</Label>
                  <Input className="form-control" type="text" placeholder='Enter Facebook Link' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, fbLink: e.target.value }))} value={formVar?.fbLink} />
                  {submit && fbLinkValid() ? <span className='d-block font-danger'>{fbLinkValid()}</span> : ""}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">Instagram Link</Label>
                  <Input className="form-control" type="text" placeholder='Enter Instagram link' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, instaLink: e.target.value }))} value={formVar?.instaLink} />
                  {submit && instaLinkValid() ? <span className='d-block font-danger'>{instaLinkValid()}</span> : ""}
                </FormGroup>
              </Col>
            </Row>


            <ModalFooter style={{ justifyContent: "center" }}>
              <Btn attrBtn={{ color: 'primary', onClick: submitNewSettings }}>Save Changes</Btn>
            </ModalFooter>
          </CardHeader>
        </Card>
      </Col>
    </Fragment>
  );
};

export default SettingsTableModel;
