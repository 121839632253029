import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  funfactData: [],
  funfactCount: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const funfactSlice = createSlice({
  name: "funfact",
  initialState,
  reducers: {
    setfunfactData(state, { payload }) {
      state.funfactData = payload.result
      state.funfactCount = payload.count
    },
    updatefunfactsData(state, { payload }) {
      const objIndex = state.funfactData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.funfactData[objIndex] = payload
      }
    },
    addFunfactData(state, { payload }) {
      state.funfactData.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    }
  }
})

export const { setfunfactData, addFunfactData, updatefunfactsData, isOpenModal, ModalToggle, isOpenStatusModal, statusToggle } = funfactSlice.actions;

export default funfactSlice.reducer;

export function getfunfact(limit, offset, status, keyword) {
  return async function getfunfactThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getFunFacts(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setfunfactData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addfunfactData(payload) {
  return async function addfunfactThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addfunfact(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          dispatch(addFunfactData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatefunfactData(payload) {
  return async function updatefunfactThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatefunfact(payload.id, payload.facts).then(
        (response) => {
          dispatch(updatefunfactsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdatefunfact(payload) {
  return async function statusUpdatefunfactThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatefunFactStatusData(payload.id, payload.status).then(
        (response) => {

          dispatch(updatefunfactsData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusDeletefunfactStatus(id, status) {
  return async function statusDeletefunfactsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatefunFactStatusData(id, status).then(
        (response) => {
          dispatch(updatefunfactsData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

