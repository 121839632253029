import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Pagination from '../../Components/Pagination/Pagination';
import SweetAlert from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { fetchcontest, isOpenModal, ModalToggle, statusToggle, isOpenStatusModal, addcontestData, updatecontestData, statusUpdatecontest, statusDeletecontestStatus } from '../../store/contestSlice';
import CustomizerContext from '../../_helper/Customizer';



const FunFactTable = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const storeVar = useSelector(state => state.contest)
  const gameId = new URLSearchParams(location.search).get('gameId');
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const { layoutURL } = useContext(CustomizerContext);
  const statusModalToggle = () => dispatch(statusToggle());
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'ACTIVE',
    stateStatus: 'PENDING',
    modalTitle: null,
    editState: false,
    contestId: '',
    title: '',
    desc: '',
    entryFee: '',

  });

  useEffect(() => {
    dispatch(fetchcontest(formVar.limit, formVar.offset, formVar.status, formVar.keyword, gameId))
  }, []);

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))

    dispatch(fetchcontest(formVar.limit, offset, formVar.status, formVar.keyword, gameId))
  };
  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    searchWithDelay(e.target.value)
  }
  const searchWithDelay = (keyword) => {
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(fetchcontest(formVar.limit, formVar.offset, formVar.status, keyword, gameId))
    }, typingDelay);
    setTypingTimer(timer);
  };

  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(fetchcontest(formVar.limit, formVar.offset, e.target.value, formVar.keyword, gameId))
  };

  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Contest',
      title: '',
      desc: '',
      entryFee: '',

    }))
    setSubmit(false);
  }
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      contestId: data.id,
      title: data.title,
      entryFee: data.entryFee,
      desc: data.desc,
      modalTitle: 'Edit Contest'
    }))
  }

  const onValueChange = (event) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      stateStatus: event.target.value,
    }))
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      contestId: data.id,
      stateStatus: data.status,
    }))
  }
  const contestDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusDeletecontestStatus(data.id, 'DELETED'))

        }
      });
  }
  const submitFacts = () => {
    if (titleValid() || amountValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updatecontestData(formVar.contestId, { title: formVar.title, desc: formVar.desc, entryFee: formVar.entryFee }))
    } else {
      dispatch(addcontestData({  title: formVar.title, desc: formVar.desc, entryFee: formVar.entryFee, gameId:gameId }))
    }
  }
  const submitStatus = () => {
    dispatch(statusUpdatecontest({ id: formVar.contestId, status: formVar.stateStatus }))
  }

  const titleValid = () => {
    if (!formVar.title) {
      return "Title is required";
    }
  }
  const amountValid = () => {
    if (!formVar.entryFee) {
      return "Entry Fee is required";
    }
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DELETED'>DELETED</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Contest
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Title</th>
                  <th scope='col'>Description</th>
                  <th scope='col'>Entry Fee</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.contestData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.title}</td>
                    <td>{item.desc}</td>
                    <td>{item.entryFee}</td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'DELETED' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DELETED' && (
                              <i className="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status}</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)}></i>
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-success action-icon'>
                        <i className="fa fa-question fa-lg" aria-hidden="true"
                          onClick={(e) => navigate('/game/contest/question/'+layoutURL+'?contestId=' + item.id)}
                          ></i>
                          <div className="tooltipCustom">Question</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-toggle-on fa-lg" onClick={(e) => statusToggleModal(item)}></i>
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <i className="fa fa-trash fa-lg" aria-hidden="true" onClick={(e) => contestDelete(item)}></i>

                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        {
          storeVar.contestCount > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.contestCount}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Title</Label>
            <Input className="form-control" type="text" placeholder='Enter Title '
              onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, title: e.target.value }))} value={formVar.title}
            />
            {submit && titleValid() ? <span className='d-block font-danger'>{titleValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Entry Fee</Label>
            <Input className="form-control" type="text" placeholder='Enter Title '
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 10)}
              onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, entryFee: e.target.value }))} value={formVar.entryFee}
            />
            {submit && amountValid() ? <span className='d-block font-danger'>{amountValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Description</Label>
            <Input className="form-control" type="text" placeholder='Enter Description '
              onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc}
            />

          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitFacts }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} desc={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={formVar.stateStatus === 'ACTIVE'} onChange={onValueChange} value='ACTIVE' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={formVar.stateStatus === 'DEACTIVE'} onChange={onValueChange} value='DEACTIVE' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DEACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={formVar.stateStatus === 'PENDING'} onChange={onValueChange} value='PENDING' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default FunFactTable;
