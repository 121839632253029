import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: "",
  homePageData: {},
}

export const homePageSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    sethomePageData(state, { payload }) {
      state.homePageData = payload
    },
  }
})


export const { sethomePageData } = homePageSlice.actions;
export default homePageSlice.reducer;


export function gethomePage() {
  return async function gethomePageThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.gethomePage().then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(sethomePageData(response.data))
          }
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}

export function updateHomePage(data) {
  return async function updateHomePageThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.UpdateHomePageData(data).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(sethomePageData(response.data))
            dispatch(successHandler("Home Page Updated Successfully"))
          }
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}
