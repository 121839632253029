import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  answerData: [],
  answerCount: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const answerSlice = createSlice({
  name: "answer",
  initialState,
  reducers: {
    setanswerData(state, { payload }) {
      state.answerData = payload.result
      state.answerCount = payload.count
    },
    updateanswersData(state, { payload }) {
      const objIndex = state.answerData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.answerData[objIndex] = payload
      }
    },
    pushanswerData(state, { payload }) {
      state.answerData.push(payload)
      state.answerCount = state.answerCount + 1
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    sliceAnswersData(state, { payload }) {
      const objIndex = state.answerData.findIndex((obj) => obj.id === payload);
      if (objIndex >= 0) {
        state.answerData.splice(objIndex, 1);
      }
      state.answerCount = state.answerCount - 1
    },
  }
})

export const { setanswerData, pushanswerData, updateanswersData, isOpenModal, ModalToggle, sliceAnswersData } = answerSlice.actions;

export default answerSlice.reducer;

export function getanswer(questionId) {
  return async function getanswerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.fetchanswers(questionId).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setanswerData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addanswerData(payload) {
  return async function addanswerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addanswer(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          dispatch(pushanswerData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateanswerData(id, ans, correct) {
  return async function updateanswerThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateanswer(id, ans, correct).then(
        (response) => {
          dispatch(updateanswersData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusDeleteanswerStatus(id, status) {
  return async function statusDeleteanswersThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateanswerStatusData(id, status).then(
        (response) => {
          dispatch(sliceAnswersData(id))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

