import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice';
import loadingReducer from './loader';
import dashboardReducer from './dashboardSlice';
import homePageReducer from './homePageSlice';
import pageReducer from './pagesSlice';
import settingsReducer from './settingsSlice';
import funfactReducer from './funFactSlice';
import gameReducer from './gameSlice';
import contestReducer from './contestSlice';
import questionReducer from './questionSlice';
import answerReducer from './answerSlice';
import blogReducer from './blogSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    loader:loadingReducer,
    dashboard:dashboardReducer,
    homePage: homePageReducer,
    page: pageReducer,
    settings: settingsReducer,
    funfact: funfactReducer,
    game: gameReducer,
    contest: contestReducer,
    question: questionReducer,
    answer: answerReducer,
    blog: blogReducer,
  },
})

export default store;