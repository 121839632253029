import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import HomePageTable from './HomePage';
const HomePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Home Page' mainTitle='Home Page' />
      <Container fluid={true}>
        <Row>
          <HomePageTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default HomePage;
