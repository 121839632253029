import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  settingsData: [],
  isOpenModal: false,
  isStatusOpenModal:false,
  isImageOpenModal:false,
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettingsData(state, { payload }) {
      state.settingsData = payload
    },
    updateSettingsData(state, { payload }) {
      state.settingsData = payload
    },
    updateSettingsLogo(state, { payload }) {
      state.settingsData.logo = payload
    },
    updateSettingsFavicon(state, { payload }) {
      state.settingsData.favIcon = payload
    },
    isOpenImageModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    imageToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  }
})

export const { setSettingsData, updateSettingsData, isOpenImageModal, imageToggle, updateSettingsLogo, updateSettingsFavicon } = settingsSlice.actions;

export default settingsSlice.reducer;


export function getSettings() {
  return async function getSettingsThunk(dispatch) {
    // dispatch(setLoading(true))
    try {
      await service.fetchSettings().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setSettingsData(response.data))          
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}



export function addSettingsLogo(payload) {
  return async function addSettingsLogoThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addSettingsLogo(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(imageToggle())
          dispatch(updateSettingsLogo(response.data))
          
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
      
    }
  }
}
export function addSettingsFavicon(payload) {
  return async function addSettingsFaviconThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addSettingsFavicon(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(imageToggle())
          dispatch(updateSettingsFavicon(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


export function updateNewSettingData(payload) {
  return async function updateNewSettingDataThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateNewSetting(payload).then(
        (response) => {
          dispatch(updateSettingsData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
