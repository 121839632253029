export const MENUITEMS = [
  // {
  //   menutitle: "Dashboard",
  //   menucontent: "Dashboards",
  //   Items: [
  //     { path: `/dashboard`, icon: "home", activeTitle:"Dashboard" , title: "Dashboard", type: "link" },

  //   ],
  // },
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      { path: `/homepage`, icon: "home", activeTitle:"homepage", title: "Home Page", type: "link" },
      { path: `/funfact`, icon: "form", activeTitle:"funfact", title: "Fun Facts", type: "link" },
      { path: `/game`, icon: "form", activeTitle:"game", title: "Games Category", type: "link" },
      { path: `/blog`, icon: "form", activeTitle:"blog", title: "Blogs", type: "link" },
      { path: `/pages`, icon: "form", activeTitle:"pages", title: "Pages", type: "link" },
      { path: `/settings`, icon: "setting", activeTitle:"settings", title: "Settings", type: "link" },
    ],
  },

];
