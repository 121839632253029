import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { updateHomePage } from '../../store/homePageSlice';

const HomePageModel = (props) => {
  const storeVar = props.props;
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    desc: '',
    editorData: '',
  });

  useEffect(() => {
    if (storeVar) {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        desc: storeVar?.desc ?? '',
      }))
    }
  }, [storeVar]);

  const submitHomepageData = () => {

    if (descValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    dispatch(updateHomePage({ desc: formVar.desc}))

  }

  const descValid = () => {
    if (!formVar.desc) {
      return "Description is required";
    }
  }

  const onFocus = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, editorData: evt }))
  }
  const onChangeDesc = (editor) => {
    const evt = editor.getData()
    setFormVar((prevFormVar) => ({ ...prevFormVar, desc: evt }))
  };
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
            </Row>
            <Row>
              <Col md="12">
                <Label className="col-form-label" for="recipient-name"> Sub Description </Label>
                <CardBody>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formVar.desc}
                    onReady={editor => {
                      onFocus(editor)
                    }}
                    onChange={(event, editor) => {
                      onChangeDesc(editor);
                    }}
                    onBlur={(event, editor) => {
                    }}
                    onFocus={(event, editor) => {
                      onFocus(editor)
                    }}
                  />
                </CardBody>
                  {submit && descValid() ? <span className='d-block font-danger'>{descValid()}</span> : ""}
              </Col>
            </Row>


            <ModalFooter style={{ justifyContent: "center" }}>
              <Btn attrBtn={{ color: 'primary', onClick: submitHomepageData }}>Save Changes</Btn>
            </ModalFooter>
          </CardHeader>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HomePageModel;
