import React, { Fragment, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Card } from 'reactstrap';
import { gethomePage } from '../../store/homePageSlice';
import HomePageModel from './HomePageModel';

const FaqsTable = () => {
  const storeVar = useSelector(state => state.homePage)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(gethomePage())
  }, []);

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
        <HomePageModel props={storeVar.homePageData}/>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FaqsTable;
