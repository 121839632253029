import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import FunFactTable from './QuestionTable';
const FunFact = () => {
  return (
    <Fragment>
      <Breadcrumbs  mainTitle='Question' parent='Game Category' subParent='Contest' subParent2='Question'/>
      <Container fluid={true}>
        <Row>
          <FunFactTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default FunFact;
