import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  contestData: [],
  contestCount: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const contestSlice = createSlice({
  name: "contest",
  initialState,
  reducers: {
    setcontestData(state, { payload }) {      state.contestData = payload.result
      state.contestCount = payload.count
    },
    updatecontestsData(state, { payload }) {
      const objIndex = state.contestData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.contestData[objIndex] = payload
      }
    },
    pushcontestData(state, { payload }) {
      state.contestData.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    }
  }
})

export const { setcontestData, pushcontestData, updatecontestsData, isOpenModal, ModalToggle, isOpenStatusModal, statusToggle } = contestSlice.actions;

export default contestSlice.reducer;

export function fetchcontest(limit, offset, status, keyword, contestId) {
  return async function fetchcontestThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getcontests(limit, offset, status, keyword, contestId).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setcontestData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addcontestData(payload) {
  return async function addcontestThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addcontest(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          dispatch(pushcontestData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatecontestData(id, payload) {
  return async function updatecontestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatecontest(id, payload).then(
        (response) => {
          dispatch(updatecontestsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdatecontest(payload) {
  return async function statusUpdatecontestThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatecontestStatusData(payload.id, payload.status).then(
        (response) => {

          dispatch(updatecontestsData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusDeletecontestStatus(id, status) {
  return async function statusDeletecontestsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatecontestStatusData(id, status).then(
        (response) => {
          dispatch(updatecontestsData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

